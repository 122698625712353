import { useOktaAuth } from "@okta/okta-react";
import { useQueries } from "@tanstack/react-query";
import { createContext, useCallback, useEffect, useState } from "react";
import { getBroadcastUserMappings, getTeamUserMappings, getUserDomesticRules, getUserInfo } from "../api/user";
import { UserConstants } from "../constants/user";
import { DomesticRule } from "../types/broadcast";
import { RoleInfo, UserInfo } from "../types/core";
import { BroadcastUserMapping, TeamUserMapping } from "../types/mappings";
import { getLocalStorageUserInfo, setLocalStorageUserInfo } from "../utils/auth";

type AuthState = {
  loggedIn: boolean;
  userInfo: UserInfo;
  teamMappings: TeamUserMapping[];
  broadcastMappings: BroadcastUserMapping[];
  userDomesticRules: DomesticRule[];
  roleInfo: RoleInfo;
};

const initialState: AuthState = {
  loggedIn: false,
  userInfo: {
    accessToken: { accessToken: "" },
    userName: "",
    userFullName: "",
    email: "",
    orgId: 0,
    orgName: "",
    orgShortName: "",
    permissions: ["NONE"],
    userId: 0
  },
  teamMappings: [],
  broadcastMappings: [],
  userDomesticRules: [],
  roleInfo: {
    adminUser: false,
    assignedClub: false,
    broadcastUser: false,
    broadcastRequester: false,
    broadcastViewerUser: false,
    clubUser: false,
    externalUser: false,
    fieldUser: false,
    milbUser: false,
    minorClubUser: false,
    minorClubSupervisorUser: false,
    scheduleUser: false,
    viewerUser: false,
    roles: new Set<string>()
  }
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: { children?: React.ReactNode }) => {
  // hooks
  const { authState } = useOktaAuth();

  // this is the state for all auth info. normally we would use a reducer,
  // but in this case we don't want to expose a "dispatch" function because
  // we want all the authorization logic to be housed here. there should not
  // be a need to "dispatch" anywhere else
  const [userInfo, setUserInfo] = useState(initialState.userInfo);
  const [roleInfo, setRoleInfo] = useState(initialState.roleInfo);

  // constants
  const loggedIn = !!authState?.isAuthenticated;

  // mappings queries
  const [{ data: teamMappings = [] }, { data: broadcastMappings = [] }, { data: userDomesticRules = [] }] = useQueries({
    queries: [
      {
        enabled: loggedIn,
        queryKey: ["teamMappings"],
        queryFn: getTeamUserMappings,
        gcTime: 0,
        staleTime: 0
      },
      {
        enabled: loggedIn,
        queryKey: ["broadcastMappings"],
        queryFn: getBroadcastUserMappings,
        gcTime: 0,
        staleTime: 0
      },
      {
        enabled: loggedIn,
        queryKey: ["userDomesticRules"],
        queryFn: getUserDomesticRules,
        gcTime: 0,
        staleTime: 0
      }
    ]
  });

  // functions
  // set the userInfo from localStorage or the service
  const setAuthInfo = useCallback(async (userData: UserInfo) => {
    // get user info from localStorage/API
    let { userName, roles } = getLocalStorageUserInfo();
    if (!userName || !roles) {
      // get the user info from the API. info should be a json object
      const batterUserInfo = await getUserInfo();
      setLocalStorageUserInfo(batterUserInfo);
      userName = batterUserInfo.username;
      roles = batterUserInfo.roles;
    }

    setUserInfo(prev => ({ ...prev, userName, permissions: roles, ...userData }));
  }, []);

  // set the roleInfo
  const setRoles = useCallback((roles: Set<string>) => {
    if (roles.has("NONE")) {
      return;
    }

    setRoleInfo({
      roles,
      adminUser: roles.has(UserConstants.ROLES.ADMIN),
      assignedClub: roles.has(UserConstants.ROLES.CLUB) || roles.has(UserConstants.ROLES.MINOR_CLUB),
      broadcastUser: roles.has(UserConstants.ROLES.BROADCAST),
      broadcastRequester: roles.has(UserConstants.ROLES.BROADCAST_REQUESTER),
      broadcastViewerUser: roles.has(UserConstants.ROLES.BROADCAST_VIEWER),
      clubUser: roles.has(UserConstants.ROLES.CLUB),
      externalUser: roles.has(UserConstants.ROLES.EXTERNAL),
      fieldUser: roles.has(UserConstants.ROLES.FIELD),
      milbUser: roles.has(UserConstants.ROLES.MINOR_CLUB) || roles.has(UserConstants.ROLES.MINOR_SUPERVISOR),
      minorClubUser: roles.has(UserConstants.ROLES.MINOR_CLUB),
      minorClubSupervisorUser: roles.has(UserConstants.ROLES.MINOR_SUPERVISOR),
      scheduleUser: roles.has(UserConstants.ROLES.SCHEDULE),
      viewerUser: roles.has(UserConstants.ROLES.VIEWER)
    });
  }, []);

  // effects
  // set user info
  useEffect(() => {
    if (authState?.isAuthenticated) {
      // when the user becomes authenticated, call setAuthInfo() to populate AuthContext's user info
      setAuthInfo({ accessToken: authState.accessToken } as UserInfo);
    }
  }, [setAuthInfo, authState]);

  // set role info
  useEffect(() => {
    if (userInfo.permissions?.length) {
      setRoles(new Set(userInfo.permissions));
    }
  }, [userInfo.permissions, setRoles]);

  return (
    <AuthContext.Provider value={{ loggedIn, userInfo, roleInfo, teamMappings, broadcastMappings, userDomesticRules }}>
      {children}
    </AuthContext.Provider>
  );
};
