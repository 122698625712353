import { createContext, useReducer } from "react";
import { Broadcast, BroadcastAvailability, BroadcastRequest, SaveAvailabilityResult } from "../types/broadcast";
import { Game, GameDoubleHeaderCount, GameFilters, GameRuleError } from "../types/game";
import { Action, ReactContext, ReactProps } from "../types/react";
import { listToDict, upsert } from "../utils/list";

type GameAction = Action & {
  games?: Game[];
  selectedGame?: Game;
  selectedBroadcast?: Broadcast;
  searchFilters?: GameFilters;
  isCreatingNew?: boolean;
  isCreatingBroadcast?: boolean;
  doubleHeaderCount?: GameDoubleHeaderCount;
  errors?: GameRuleError[];
  broadcastRequests?: BroadcastRequest[];
  availabilityOptions?: BroadcastAvailability[];
  bulkSaveBlackoutResults?: SaveAvailabilityResult[];
};

type GameState = ReactContext<GameAction> & {
  games: Game[];
  selectedGame: Game | null;
  selectedBroadcast: Broadcast | null;
  searchFilters: GameFilters | null;
  isCreatingNew: boolean;
  isCreatingBroadcast: boolean;
  isUpdatingOutOfMarketAvailability: boolean;
  doubleHeaderCount: GameDoubleHeaderCount;
  errors: GameRuleError[];
  broadcastRequestInfoByBatterPk: { [x: number]: BroadcastRequest[] };
  availabilityOptions: BroadcastAvailability[];
};

const initialState: GameState = {
  dispatch: () => {},
  games: [],
  selectedGame: null,
  searchFilters: null,
  isCreatingNew: false,
  isCreatingBroadcast: false,
  isUpdatingOutOfMarketAvailability: false,
  doubleHeaderCount: {
    totalCount: 0
  },
  selectedBroadcast: null,
  errors: [],
  broadcastRequestInfoByBatterPk: {},
  availabilityOptions: []
};

export const GameContext = createContext(initialState);

const reducer = (state: GameState, action: GameAction): GameState => {
  switch (action.type) {
    case "setGameRuleErrors":
      return {
        ...state,
        errors: action.errors || []
      };
    case "clearGameRuleErrors":
      return {
        ...state,
        errors: []
      };
    case "setGamesAfterSearch": {
      const { games = [], searchFilters = null } = action;
      return {
        ...state,
        games,
        searchFilters,
        isCreatingNew: false,
        selectedGame: null,
        doubleHeaderCount: getDoubleHeaderCount(games)
      };
    }
    case "setGamesWithBroadcastRequestInfo": {
      const { games = [], broadcastRequests = [] } = action;
      const result: { [x: number]: BroadcastRequest[] } = {};
      for (const broadcastRequest of broadcastRequests) {
        const primaryPk = broadcastRequest.primaryGame?.batterPk;
        const secondaryPk = broadcastRequest.secondaryGame?.batterPk;

        if (primaryPk) {
          if (!result[primaryPk]) {
            result[primaryPk] = [];
          }
          result[primaryPk].push(broadcastRequest);
        }

        if (secondaryPk) {
          if (!result[secondaryPk]) {
            result[secondaryPk] = [];
          }
          result[secondaryPk].push(broadcastRequest);
        }
      }

      return {
        ...state,
        games,
        broadcastRequestInfoByBatterPk: result
      };
    }
    case "postBulkUpdateGameBlackouts": {
      const { bulkSaveBlackoutResults = [] } = action;
      const availabilityByBatterPk = listToDict(bulkSaveBlackoutResults, i => i.batterPk);
      return {
        ...state,
        games: state.games.map((game: Game): Game => {
          const availabilityInfo = availabilityByBatterPk[game.batterPk];

          return {
            ...game,
            broadcastAvailabilityOption: availabilityInfo?.availabilityOption,
            broadcastAvailabilities: availabilityInfo?.sourceIds?.map(s => ({
              batterPk: game.batterPk,
              broadcastSourceId: s
            }))
          };
        })
      };
    }
    case "createGame":
      return {
        ...state,
        isCreatingNew: true,
        selectedGame: null
      };
    case "createGameBroadcast":
      return {
        ...state,
        isCreatingBroadcast: true,
        selectedBroadcast: null
      };
    case "selectGame":
      return {
        ...state,
        isCreatingNew: false,
        selectedBroadcast: null,
        selectedGame: action.selectedGame || null
      };
    case "selectGameBroadcast":
      return {
        ...state,
        isCreatingBroadcast: false,
        selectedBroadcast: action.selectedBroadcast || null,
        selectedGame: action.selectedGame || state.selectedGame
      };
    case "saveGame": {
      const { selectedGame = null } = action;
      const games = state.games.map(g => (g.batterPk === selectedGame?.batterPk ? selectedGame : g));
      return {
        ...state,
        games,
        selectedGame,
        isCreatingNew: false,
        doubleHeaderCount: getDoubleHeaderCount(games)
      };
    }
    case "saveGameBroadcast": {
      // take the broadcast and update the game
      const { selectedGame } = state;
      const { selectedBroadcast } = action;

      if (!selectedGame || !selectedBroadcast) {
        return state;
      }

      const updatedGame: Game = {
        ...selectedGame,
        broadcasts: upsert(selectedBroadcast, selectedGame.broadcasts || [], b => b.id === selectedBroadcast.id)
      };

      // update the games as well
      const games = state.games.map(g => (g.batterPk === updatedGame?.batterPk ? updatedGame : g));
      return {
        ...state,
        games,
        selectedGame: updatedGame
      };
    }
    case "deleteGame": {
      const games = state.games.filter(g => g.batterPk !== action.selectedGame?.batterPk);
      return {
        ...state,
        games,
        selectedGame: null,
        selectedBroadcast: null,
        doubleHeaderCount: getDoubleHeaderCount(games)
      };
    }
    case "deleteGameBroadcast": {
      // take the broadcast and update the game
      const { selectedGame } = state;
      const { selectedBroadcast } = action;

      if (!selectedGame || !selectedBroadcast) {
        return state;
      }

      const updatedGame = {
        ...selectedGame,
        broadcasts: selectedGame.broadcasts?.filter(b => b.id !== selectedBroadcast.id)
      };

      // update the games as well
      const games = state.games.map(g => (g.batterPk === updatedGame?.batterPk ? updatedGame : g));
      return {
        ...state,
        games,
        selectedGame: updatedGame,
        selectedBroadcast: null
      };
    }
    case "closeGame":
      return {
        ...state,
        isCreatingNew: false,
        selectedGame: null
      };
    case "closeGameBroadcast":
      return {
        ...state,
        isCreatingBroadcast: false,
        selectedBroadcast: null
      };
    case "reset":
      return initialState;
    case "setOutOfMarketAvailability":
      return {
        ...state,
        availabilityOptions: action.availabilityOptions || []
      };
    case "updateOutOfMarketAvailability":
      return {
        ...state,
        isUpdatingOutOfMarketAvailability: true
      };
    case "closeUpdateOutOfMarket":
      return {
        ...state,
        selectedBroadcast: null,
        isUpdatingOutOfMarketAvailability: false
      };
    default:
      return state;
  }
};

export const GameProvider = ({ children }: ReactProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <GameContext.Provider value={{ ...state, dispatch }}>{children}</GameContext.Provider>;
};

const getDoubleHeaderCount = (games: Game[]) => {
  const dhMap = getDoubleHeadersMap(games);
  let splitDhCount = 0;
  let traditionalDhCount = 0;
  Object.keys(dhMap).forEach(gameId => {
    const dhGames = dhMap[gameId].games;
    const splitDhGames = dhGames.filter(g => g.doubleHeader === "S");
    const traditionalDhGames = dhGames.filter(g => g.doubleHeader === "Y");
    if (gameId === "tbd") {
      splitDhCount += splitDhGames.length;
      traditionalDhCount += traditionalDhGames.length;
    } else if (splitDhGames.length === dhGames.length) {
      splitDhCount += 1;
    } else if (traditionalDhGames.length === dhGames.length) {
      traditionalDhCount += 1;
    }
  });

  return {
    splitCount: splitDhCount,
    traditionalCount: traditionalDhCount,
    totalCount: splitDhCount + traditionalDhCount
  };
};

const DH_SET = new Set(["S", "Y"]);

const getDoubleHeadersMap = (games: Game[]) => {
  const dhMap: { [x: string]: { games: Game[] } } = {};
  const dhGames = games.filter(g => DH_SET.has(g.doubleHeader));
  dhGames.forEach(game => {
    const dhGameId = game.gameDate ? `${game.gameDate}_${game.awayTeamAbbrev}_${game.homeTeamAbbrev}` : "tbd";
    const dhGameIdHomeAwaySwapped = `${game.gameDate}_${game.homeTeamAbbrev}_${game.awayTeamAbbrev}`;
    if (dhMap.hasOwnProperty(dhGameId)) {
      dhMap[dhGameId].games.push(game);
    } else if (dhMap.hasOwnProperty(dhGameIdHomeAwaySwapped)) {
      dhMap[dhGameIdHomeAwaySwapped].games.push(game);
    } else {
      dhMap[dhGameId] = { games: [game] };
    }
  });

  return dhMap;
};
